import React, { useState } from "react";
import "./About.css";
import headerDrop from "../../Assets/icons/headerDrop.png";
import folderGreen from "../../Assets/icons/folders/green.png";
import folderOrange from "../../Assets/icons/folders/orange.png";
import folderBlue from "../../Assets/icons/folders/blue.png";
import PersonalInfo from "./PersonalInfoFolder/PersonalInfo";
import email from "../../Assets/icons/mail-icon.png";
import phone from "../../Assets/icons/phone-icon.png";
import cross from "../../Assets/icons/close-icon.png"; 
import { useGetUserQuery } from "../../redux/features/api/createAPI";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

const About = () => {
  // Show File
  const [showedFile, setShowdFile] = useState("Profile");
  // sidebar states
  const [isOpenPersonalInfo, setisOpenPersonalInfo] = useState(true);
  const [isOpencontact, setisOpencontact] = useState(true);

  const { data } = useGetUserQuery("mahmudulmk4@gmail.com");

  const skills = [
    "devicon-javascript-plain",
    "devicon-typescript-plain",
    "devicon-react-original",
    "devicon-redux-original",
    "devicon-nextjs-original-wordmark",
    "devicon-nodejs-plain",
    "devicon-express-original",
    "devicon-html5-plain",
    "devicon-css3-plain",
    "devicon-tailwindcss-original",
    "devicon-bootstrap-plain",
    "devicon-mongodb-plain",
    "devicon-postgresql-plain",
    "devicon-antdesign-plain",
    "devicon-materialui-plain",
    "devicon-firebase-plain",
    "devicon-git-plain",
    "devicon-github-original",
    "devicon-amazonwebservices-plain-wordmark",
    "devicon-jest-plain",
    "devicon-json-plain",
    "devicon-postman-plain",
  ];

  return (
    <div className="md:flex h-full ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mahmudul Hasan MK - About</title>
      </Helmet>
      <div className="md:w-2/12 border-r border-border-bg md:flex pl-4">
        <div className="md:w-10/12 w-full">
          <div>
            <div className="text-left flex items-center p-2 w-full border-b border-border-bg">
              {isOpenPersonalInfo ? (
                <img src={headerDrop} className="pr-4 " alt="" />
              ) : (
                <img src={headerDrop} className="pr-4 -rotate-90 " alt="" />
              )}
              <h2
                className="text-lg font-normal cursor-pointer"
                onClick={() => {
                  setisOpenPersonalInfo(!isOpenPersonalInfo);
                }}
              >
                personal-info
              </h2>
            </div>
            {isOpenPersonalInfo && (
              <motion.ul
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3 }}
                className="p-2"
              >
                <PersonalInfo
                  folder={folderOrange}
                  content="bio"
                  OpenStatus={true}
                  file={{ fileName: "Profile", setShowdFile }}
                ></PersonalInfo>
                <PersonalInfo
                  folder={folderGreen}
                  content="Experience"
                  file={{ fileName: "Experience", setShowdFile }}
                ></PersonalInfo>
                <PersonalInfo
                  folder={folderBlue}
                  content="educations"
                  file={{ fileName: "Education", setShowdFile }}
                ></PersonalInfo>
              </motion.ul>
            )}
          </div>
          <div className="my-4">
            <div className="text-left flex items-center p-2 w-full border-b border-t border-border-bg">
              {isOpencontact ? (
                <img src={headerDrop} className="pr-4 " alt="" />
              ) : (
                <img src={headerDrop} className="pr-4 -rotate-90 " alt="" />
              )}
              <h2
                className="text-lg font-normal cursor-pointer"
                onClick={() => {
                  setisOpencontact(!isOpencontact);
                }}
              >
                Contacts
              </h2>
            </div>
            {isOpencontact && (
              <motion.ul
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.3 }}
                className="p-2"
              >
                <li className="my-2 flex items-center  text-text text-sm">
                  <img src={email} className="pr-2" alt="" />
                  <a href={`mailto:${data?.data?.email}`}>
                    {data?.data?.email}
                  </a>
                </li>
                <li className="my-2 flex items-center  text-text ">
                  <img src={phone} className="pr-2" alt="" />
                  <span>{data?.data?.phoneNumber}</span>
                </li>
              </motion.ul>
            )}
          </div>
        </div>
      </div>
      <div className="md:w-10/12 h-full overflow-hidden">
        <div className="p-2 text-left border-b border-border-bg">
          <h2 className="text-base text-text flex ">
            <span>
              {showedFile === "Profile" && "Profile"}{" "}
              {showedFile === "Education" && "Education"}{" "}
              {showedFile === "Experience" && "Experience"}
            </span>{" "}
            <img
              onClick={() => {
                setShowdFile("Profile");
              }}
              className="px-10"
              src={cross}
              alt=""
            />
          </h2>
        </div>
        <div className="md:flex md:h-full">
          <div className="md:w-1/2 border-r border-border-bg relative">
            {showedFile === "Profile" && (
              <ul className="text-text text-left py-4 px-6 overflow-scroll h-[80vh] about-profile">
                <li className="text-center text-lg font-bold py-2 text-text-yellow">
                  <span>/** About Me ** /</span>
                </li>
                {data?.data?.aboutMe &&
                  data?.data?.aboutMe.map((singelLine, index) => (
                    <motion.li
                      key={index}
                      initial={{ opacity: 0, y: 100 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 * index }}
                      className="flex py-4"
                    >
                      <span>{singelLine}</span>
                    </motion.li>
                  ))}
              </ul>
            )}
            {showedFile === "Education" && (
              <ul className="text-text text-left py-4 px-6">
                <li className="text-center text-lg font-bold py-2 text-text-yellow">
                  <span>/** Experience ** /</span>
                </li>

                {data?.data?.education &&
                  data?.data?.education.map((edu, index) => (
                    <motion.li
                      initial={{ opacity: 0, y: 100 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 * index }}
                      className="px-4 py-6 "
                    >
                      <ul className="flex ">
                        <li className="font-bold">{edu.degreeTitle},</li>
                        <li className="pl-2">{edu.university}</li>
                      </ul>
                      <p className="py-2">{edu.about}</p>
                      <h3 className="font-semibold">{edu.duration}</h3>
                    </motion.li>
                  ))}
              </ul>
            )}
            {showedFile === "Experience" && (
              <ul className="text-text text-left py-4 px-6">
                <li className="text-center text-lg font-bold py-2 text-text-yellow">
                  <span>/** Experience ** /</span>
                </li>

                {data?.data?.experience &&
                  data?.data?.experience.map((exp, index) => (
                    <motion.li
                      initial={{ opacity: 0, y: 100 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3 * index }}
                      className="px-4 py-6 "
                    >
                      <ul className="flex justify-between">
                        <li className="font-bold">{exp.title}</li>
                        <li>{exp.company}</li>
                      </ul>
                      <p className="py-2">{exp.about}</p>
                      <h3 className="font-semibold">{exp.duration}</h3>
                    </motion.li>
                  ))}
              </ul>
            )}

            <div className="absolute h-full w-5 border-l border-border-bg  top-0 right-0">
              <div className=" bg-border-bg h-3 w-full"></div>
            </div>
          </div>
          {/* Skills and tools */}
          <div className="md:w-1/2 relative">
            <div className="py-4   mx-4 ">
              <h2 className="text-center text-lg font-bold py-2 text-text-yellow">
                <span>Languages and Tools</span>
              </h2>
              <div className="mr-4 text-xl grid grid-cols-3 md:grid-cols-4 lg:grid-cols-5  mt-10">
                {skills.map((i, index) => (
                  <div
                    key={index}
                    className="cursor-pointer pointer hover:bg-text hover:text-text-yellow flex items-center justify-center h-16 w-26 py-2 px-2 border-2 border-text hover:scale-125 transition     "
                  > 
                    <i className={i}></i>
                  </div>
                ))}
              </div>
            </div>
            <div className="absolute h-full w-5 border-l border-border-bg  top-0 right-0">
              <div className=" bg-border-bg h-3 w-full"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
