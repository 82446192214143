import React from "react";
import { useGetprojectsQuery } from "../../../redux/features/api/createAPI";
import { useParams } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import { motion } from "framer-motion";
import { Autoplay, Pagination, Navigation } from "swiper";
import linkicon from "../../../Assets/icons/link.png";
import staroutline from "../../../Assets/icons/staroutline.png";
import TagButton from "../../../Components/Button/TagButton";

const ProjectDetails = () => {
  // Project Id

  const { id: projectId } = useParams();

  // Get all projects
  const { data } = useGetprojectsQuery("mahmudulmk4@gmail.com");

  const project = data?.data.filter((project) => project._id === projectId);

  return (
    <div className="overflow-y-scroll min-w-[400px] w-full max-w-[1280px] mx-auto">
      {project && (
        <div>
          <motion.div
            initial={{ y: "100%" }}
            animate={{ y: "0%" }}
            transition={{ type: "spring", mass: 0.3 }}
            className="overflow-hidden "
          >
            <div className="mx-4 my-2   text-left">
              <div className="sm:flex my-6 justify-between">
                <div>
                  <h2 className="text-text-yellow text-[30px] md:text-[30px] py-2 font-semibold text-left ">
                    {project[0].projectTitle}
                  </h2>
                  {project[0].projectKeyWord ? (
                    <p className="text-[14px]">{project[0].projectKeyWord}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="my-4  my-6  ">
                  {project[0].sourceCode && (
                    <button className="mx-4 bg-button hover:bg-text-yellow px-3.5 py-2.5 rounded-lg text-sm ">
                      <a
                        href={project[0].sourceCode}
                        target="_blank"
                        rel="noreferrer"
                        className="flex space-x-2"
                      >
                        <span>Source Code </span>
                        <img src={linkicon} alt="" />
                      </a>
                    </button>
                  )}
                  <button className="bg-button hover:bg-text-yellow px-3.5 py-2.5 rounded-lg text-sm ">
                    <a
                      href="https://github.com/mkmahmud"
                      target="_blank"
                      rel="noreferrer"
                      className="flex space-x-2"
                    >
                      <span>Preview </span>
                      <img src={linkicon} alt="" />
                    </a>
                  </button>
                </div>
              </div>
              <div className=" ">
                <Swiper
                  spaceBetween={50}
                  slidesPerView={1}
                  autoplay={{
                    delay: 2000,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                >
                  {project[0].gellaryImages.map((img, index) => (
                    <SwiperSlide key={index}>
                      {" "}
                      <img
                        src={img}
                        alt=""
                        className="my-2 mx-auto w-full h-auto max-w-full md:max-w-[1000px] rounded-lg"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
              {/* Status */}
              {project[0].status ? (
                <div className="flex items-center  my-10">
                  <h2 className="text-xl text-text-yellow font-bold">
                    Status:
                  </h2>
                  <div>
                    <TagButton text={project[0].status}></TagButton>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* Overview */}
              <div className="  my-10">
                <h2 className="text-xl text-text-yellow font-bold">
                  Overview:
                </h2>
                <div>
                  {project[0].overview.map((i, index) => (
                    <p key={index} className="p-2">
                      {i}
                    </p>
                  ))}
                </div>
              </div>
              {/* Goals */}
              {project[0].goals ? (
                <div className="  my-10">
                  <h2 className="text-xl text-text-yellow font-bold">Goals:</h2>
                  <div>
                    {project[0].goals.map((i, index) => (
                      <p key={index} className="p-2">
                        ● {i}
                      </p>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* TimeLine */}
              {project[0].timeline ? (
                <div className="  my-10">
                  <h2 className="text-xl text-text-yellow font-bold">
                    Timeline:
                  </h2>
                  <div>
                    <p className="p-2">
                      Start Date: <b>{project[0].timeline.startDate}</b>
                    </p>
                    <p className="p-2">
                      Current Phase: <b>{project[0].timeline.currentPhase}</b>
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* Team */}
              <div className="  my-10">
                <h2 className="text-xl text-text-yellow font-bold">Team:</h2>
                <div>
                  <p className="p-2">{project[0].developersRole}</p>
                </div>
              </div>
              {/* Challenges */}
              <div className="  my-10">
                <h2 className="text-xl text-text-yellow font-bold">
                  Challenges:
                </h2>
                <div>
                  <ul className="px-2">
                    {project[0].challenges.map((challange, index) => (
                      <li
                        key={index}
                        className="flex items-start md:pl-10 my-4"
                      >
                        <img
                          src={staroutline}
                          alt="Challange"
                          className="w-[30px]"
                        />
                        <span className="p-2">{challange} </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* Features */}
              <div className="  my-10">
                <h2 className="text-xl text-text-yellow font-bold">
                  Features:
                </h2>
                <div>
                  <ul className="px-2">
                    {project[0].features.map((feature, index) => (
                      <li
                        key={index}
                        className="flex items-start md:pl-10 my-4"
                      >
                        <img
                          src={staroutline}
                          alt="features"
                          className="w-[30px]"
                        />{" "}
                        <span className="p-2">{feature} </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              {/* testingAndQualityAssurance */}
              {project[0].testingAndQualityAssurance ? (
                <div className="  my-10">
                  <h2 className="text-xl text-text-yellow font-bold">
                    Testing And Quality Assurance:
                  </h2>
                  <div>
                    {project[0].testingAndQualityAssurance.map((i, index) => (
                      <p key={index} className="p-2">
                        ● {i}
                      </p>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* scalabilityAndFuturePlans */}
              {project[0].scalabilityAndFuturePlans ? (
                <div className="  my-10">
                  <h2 className="text-xl text-text-yellow font-bold">
                    Scalability And Future Plans:
                  </h2>
                  <div>
                    {project[0].scalabilityAndFuturePlans.map((i, index) => (
                      <p key={index} className="p-2">
                        ● {i}
                      </p>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* securityMeasures */}
              {project[0].securityMeasures ? (
                <div className="  my-10">
                  <h2 className="text-xl text-text-yellow font-bold">
                    Security Measures:
                  </h2>
                  <div>
                    {project[0].securityMeasures.map((i, index) => (
                      <p key={index} className="p-2">
                        ● {i}
                      </p>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* architecture */}
              {project[0].architecture ? (
                <div className="flex items-center  my-10">
                  <h2 className="text-xl text-text-yellow font-bold">
                    Architecture:
                  </h2>
                  <div>
                    <TagButton text={project[0].architecture}></TagButton>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* Technologies */}
              <div className="  my-10">
                <h2 className="text-xl text-text-yellow font-bold">
                  Technologies:
                </h2>
                <div>
                  {project[0].Technologies.map((i, index) => (
                    <TagButton text={i} key={index}></TagButton>
                  ))}
                </div>
              </div>
              <div className="  my-10">
                <h2 className="text-xl text-text-yellow font-bold">Outcome:</h2>
                <div>
                  <p className="p-2">{project[0].Result}</p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default ProjectDetails;
