import React from "react";
import ProjectsCard from "./ProjectsCard/ProjectsCard";
// Import Swiper styles
import "swiper/css";
import "./Projects.css";

import { useSelector } from "react-redux";
import { useGetprojectsQuery } from "../../redux/features/api/createAPI";
import { Helmet } from "react-helmet";

const Projects = () => {
  const myProjects = useSelector((state) => state.myProjects);

  const { data, isLoading } = useGetprojectsQuery("mahmudulmk4@gmail.com");

  const sortedData = [...(data?.data || [])].sort(
    (a, b) => a.projectID - b.projectID
  );

  return (
    <div className="md:flex h-full bg-sec-bg   md:overflow-hidden ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mahmudul Hasan MK - Projects</title>
      </Helmet>
      {/* Loading */}
      {!data?.data && (
        <div className="h-full w-full">
          <div className="flex items-center justify-center absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 ">
            <div className="w-80 h-80 border-t-4 border-b-4 border-green-900 rounded-full animate-spin"></div>
          </div>
        </div>
      )}

      {data?.data && (
        <div className="overflow-y-auto   mt-4 md:mx-20 lg:mx-40 2xl:mx-60  w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-2">
          {isLoading && (
            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className="flex items-center justify-center absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 ">
                <div className="w-80 h-80 border-t-4 border-b-4 border-green-900 rounded-full animate-spin"></div>
              </div>
            </div>
          )}
          {data?.data &&
            !myProjects.openProject &&
            sortedData.map((project, index) => (
              <ProjectsCard
                data={project}
                projectId={index + 1}
                key={project._id}
              >
                {" "}
              </ProjectsCard>
            ))}
        </div>
      )}
    </div>
  );
};

export default Projects;
